
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpOrderEntity from "../entity";
import ErpOrderGeneral from "./show/general";
import ErpOrderAddress from "./show/address";
import ErpOrderDocument from "./show/document";
import UtilsErp from "../../../../utils/erp";
import Tagify from '@yaireo/tagify';
import {createElement} from "flatpickr/dist/utils/dom";
import ErpOrderShipment from "./show/shipment";
import ErpOrderWMS from "./show/wms";
export default class ErpOrderShow extends DefaultController {
    async init() {
        this.entity = "erp/orders"
        this.childs = {
            general: new ErpOrderGeneral(this),
            address: new ErpOrderAddress(this),
            document: new ErpOrderDocument(this),
            shipment: new ErpOrderShipment(this)
        }
        if (window.siteFeatures && window.siteFeatures.wms)
            this.childs.wms = new ErpOrderWMS(this)
        this.bindListeners();
        if (this.id) {
            await this.getEntity();
            await Utils.showLoader();
        } else {
            await Utils.createSelects(this.data, (elem: any) => { return this.onSelectGetFilterOptions(elem)}, (data: any, entity: any, element: any) => { return this.onSelectFilterResults(data, entity, element)});
        }
        await this.loadOrderStates();
        await Utils.hideLoader();
    }

    protected getEntityData(elem: any) {
        return ErpOrderEntity.getEntityData(elem)
    }

    async loadOrderStates() {
        const deliveryStatusSelect = document.querySelector("#erp_order_delivery_status_id") as HTMLSelectElement;
        const statusSelect = document.querySelector("#erp_order_status_id") as HTMLSelectElement;

        const statesReply = await fetch(`/api/v1/erp/actions/order_state_transitions?order_id=${this.data.id}`)
        const states = await statesReply.json();
        const deliveryStatesReply = await fetch(`/api/v1/erp/actions/order_delivery_state_transitions?order_id=${this.data.deliveries[0].id}`)
        const deliveryStates = await deliveryStatesReply.json();

        deliveryStatusSelect.innerHTML = `<option value='${this.data.deliveries[0].stateMachineState[0].id}'>${this.data.deliveries[0].stateMachineState[0].name}</option>${this.createStateOptions(deliveryStates.data)}`;
        statusSelect.innerHTML = `<option value='${this.data.stateMachineState[0].id}'>${this.data.stateMachineState[0].name}</option>${this.createStateOptions(states.data)}`;
    }

    createStateOptions(data: any) {
        let html = ``;
        data.transitions.forEach((state: any) => {
            html += `<option value='${state.technicalName}' data-transition='${state.actionName}'>${state.name}</option>`;
        })

        return html;
    }

    bindListeners() {
        jQuery("#new_erp_order_line_item_product_id").on("select2:ajax_done", async (e, data: any) => {
            console.log("data", data)
            if (data && data.status === 200 && data.data.length === 1  && data.data[0].is_serial_match === true) {
                const d: any = data.data[0];
                setTimeout(async () => {
                    (document.querySelector("#erp_order_line_item_serials") as HTMLInputElement).value = d.serials[d.serial_match_index];
                    (document.querySelector("#erp_order_line_item_unitPrice") as HTMLInputElement).value = d.product.price.gross;
                    (document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value = "1";

                    const option = new Option(d.product.name, d.product.uuid.toLowerCase(), true, true);
                    jQuery('#new_erp_order_line_item_product_id').append(option).trigger({
                        type: 'select2:select',
                        params: {
                            data: {
                                data: {
                                    ...d,
                                    serials: d.serials[d.serial_match_index],
                                    ids: [d.ids[d.serial_match_index]],
                                }
                            }
                        }
                    }).trigger('change');
                    jQuery('#new_erp_order_line_item_product_id').select2('close');
                    //@ts-ignore
                    await this.childs.general.submitLineItem();
                }, 200);
            }
        });

        jQuery("#new_erp_order_line_item_product_id").on("select2:select", async (e, data: any) => {
            await this.addWarehouseInfo(e);
        });
        (document.querySelector("#erp_order_save") as HTMLButtonElement).addEventListener("click", async (e: any) => {
            await Utils.showLoader()
            const data = this.getEntityData(document.querySelector("div.row[data-entity-id]"));
            await Utils.entity.upsert(data, "erp/orders")

            const deliveryStatusSelect = document.querySelector("#erp_order_delivery_status_id") as HTMLSelectElement;
            const statusSelect = document.querySelector("#erp_order_status_id") as HTMLSelectElement;
            deliveryStatusSelect.innerHTML = '';
            statusSelect.innerHTML = '';
            //@ts-ignore
            const ticketUuid = document.querySelector("#erp_order_ticket_uuid option:checked")?.value as string
            //@ts-ignore
            const orderUuid = document.querySelector("#erp_order_id")?.value as string

            //@ts-ignore
            const externalTicket = document.querySelector("#erp_order_external_ticket_name")?.value as string
            await Utils.entity.upsert({order_uuid: orderUuid, ticket_uuid: ticketUuid, external_ticket: externalTicket}, 'ticket_order_maps')
            await this.getEntity();
            await this.loadOrderStates();
            await Utils.hideLoader();
        });

        (document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).addEventListener("keyup", (e) => {
            this.checkQuantityProductQuantity();
        });

        jQuery("body").delegate(".line_item_warehouse_map", "keyup change", (e) => {
            e.preventDefault();
            const target = e.target;
            const maxQuantity = parseInt(target.getAttribute("data-max-quantity"));
            const currentQuantity = parseInt(target.value);
            if(maxQuantity < currentQuantity) {
                target.value = String(maxQuantity);
            }
            this.checkQuantityProductQuantity();
        })
    }

    checkQuantityProductQuantity() {
        const fullQuantity = parseInt((document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value);
        let usedQuantity = 0;
        const inputs = document.querySelectorAll(".line_item_warehouse_map");
        inputs.forEach((input: any) => {
            usedQuantity += parseInt(input.value)
        })
        const saveBtn = document.querySelector("#erp_line_item_add_save_button") as HTMLButtonElement
        if (fullQuantity === usedQuantity) {
            saveBtn.removeAttribute("disabled");
        } else {
            saveBtn.setAttribute("disabled", "disabled");
        }
    }

    async addWarehouseInfo(data: any) {
        if (data.params.data.id) {
            const r = await UtilsErp.stockInfo(data.params.data.id.toLowerCase());
            // @ts-ignore
            const stockInfo = r.data.filter(d => d.customer.uuid && d.customer.uuid.toLowerCase() === this.data.orderCustomer[0].customerId.toLowerCase());
            let html = '';
            const tbody = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
            tbody.innerHTML = "";
            stockInfo.forEach((stock: any) => {
                let name = `${stock.warehouse.name}, ${stock.warehouse_location.name}`
                if (stock.warehouse_location_pallet.uuid) {
                    name += `, ${stock.warehouse_location_pallet.name}`
                }
                let options = ``
                stock.serials.forEach((sn: string) => {
                    console.log(sn[0])
                    if (sn[0] && stock.serials_reserved.flat().indexOf(sn[0]) === -1) {
                        options += `<option value='${sn[0]}'>${sn[0]}</option>`
                    }
                })
                let availableStock = stock.stock - stock.stock_reserved
                if (availableStock > 0) {

                    html += `<tr>
<td style="width: 100%;">
<div class="row align-items-center">
 <div class="col-12 mb-1" style="font-size:12px;">${name}</div>
 <div class="col-6 mb-1">
 <input class="form-control line_item_warehouse_map" value="0" type="number" min="0" max="${availableStock}" data-max-quantity="${availableStock}" data-warehouse-id="${stock.warehouse.uuid}" data-warehouse-location-id="${stock.warehouse_location.uuid}" ${stock.warehouse_location_pallet.uuid ? `data-warehouse-location-pallet-id="${stock.warehouse_location_pallet.uuid}"` : ``} />

</div>
 <div class="col-6 mb-1"> / ${availableStock}</div>
 <div class="col-12">
 <label class="form-label">Serials: </label>
 <select class="form-select" multiple>
 ${options}
</select>
 </div>
</td>
</tr>`
                }
            })
            tbody.innerHTML = html;
            console.log("Stock Info", stockInfo)
        }
    }
    async updateEntity(data: any) {
        await super.updateEntity(data);
        const tagifyElem = document.querySelector('#erp_order_tracking_numbers') as HTMLInputElement
        const tagifyList = new Tagify(tagifyElem, {
            dropdown: {
                position: 'text',
                enabled: 1
            }
        });

        tagifyElem.addEventListener('change', async (e: any) => {
            const v = JSON.parse(e.target.value)
            //@ts-ignore
            const values = v.map((e: any) => e["value"]);
            //@ts-ignore
            const orderUuid = document.querySelector("#erp_order_id")?.value as string
            await Utils.entity.upsert({order_uuid: orderUuid, tracking_numbers: values}, 'order_tracking_maps')
        });
        tagifyElem.addEventListener('remove', async (e: any) => {
            const v = JSON.parse(e.target.value)
            //@ts-ignore
            const values = v.map((e: any) => e["value"]);
            //@ts-ignore
            const orderUuid = document.querySelector("#erp_order_id")?.value as string
            await Utils.entity.upsert({order_uuid: orderUuid, tracking_numbers: values}, 'order_tracking_maps')
        });

        const button = document.querySelector('#erp_order_tracking_numbers_add') as HTMLButtonElement;

        button.addEventListener('click', () => {
            tagifyList.addEmptyTag();
        });

        const input = document.querySelector("#erp_order_ticket_uuid") as HTMLInputElement;
        const inputParent = input.closest("div[class^='col-']") as HTMLDivElement;
        const row = inputParent.closest("div.row") as HTMLDivElement;
        const buttonCol = row.querySelector("div.col-4")
        if (buttonCol) {
            row.removeChild(buttonCol)
        }
        if (data.ticket) {
            inputParent.classList.remove("col-12")
            inputParent.classList.remove("col-8")
            inputParent.classList.add("col-8")
            const buttonDiv = document.createElement("div")
            buttonDiv.classList.add("col-4");
            buttonDiv.innerHTML = `<a href="/${currentLocale}/ticket/tasks/${data.ticket.uuid}"><button class="btn btn-primary w-100">Open</button></a>`
            row.appendChild(buttonDiv)
        }
    }


    protected async addLinePallet(data: any, orderId = null) {
        const r = await UtilsErp.addPalletOrder(data, undefined, orderId);
        (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled");
        return r;
    }
    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("id") === "new_erp_order_line_item_product_id") {
            return {
                customer_id: this.data.orderCustomer[0].customerId
            }
        } else if (elem.getAttribute("data-entity") === "tickets") {
            console.log(this.data)
            return {
                customer_id: this.data.orderCustomer[0].customFields.fltools_customer_uuid
            }
        } else {
            return {
            }
        }
    }

}